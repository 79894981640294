.input-group {
    margin: 2rem 0;
}

ul .list-item {
    list-style-type: none;
    list-style-position: outside;
    padding: 10px;
    float: left;
}

input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked, input[type="radio"]:not(:checked),
input[type="radio"]:checked {
    position: absolute;
    left: -9999%;
}

input[type="checkbox"] + label, input[type="radio"] + label {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #4c4c4c;
    border-radius: .3rem;
    color: black;
    background-color: #bdbdbd;
    margin-bottom: 10px;
    width: 13rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

}

input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
    border: 1px solid white;
    color: white;
    background-color: #43c1b9;
}

input[type="checkbox"]:checked + label, input[type="radio"]:checked + label > .label-option {
    border: 1px solid white;
}

.label-option {
    border: 1px solid #43c1b9;
    padding: 6px;
    border-radius: 4px;
    height: 25px;
    display: flex;
    align-items: center;
}

.tell-us {
    border: none;
    outline: none;
    border-bottom: 2px solid #43c1b9;
    background: transparent;
    width: 80%;
    font-size: 16px;
}

.tell-us::placeholder {
    color: #43c1b9;
    font-size: 16px;
    margin: 10px 0;
    font-style: italic;
}

.animated-hire {
    transform-origin: top;
    animation: slide-up-name 0.8s;
}

@keyframes slide-animated-hire {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}


@media only screen and (max-width: 767px) {
    input[type="checkbox"] + label, input[type="radio"] + label {
        width: 10rem;
    }
}
