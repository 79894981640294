.hs-icon {
    margin-top: 0.2rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #43c1b9;
    border-radius: 50%;
}

.hc-para {
    padding-right: 1rem;
}

.hs-fa-check {
    font-size: 0.4rem;
    color:white;
}

.cs-img-main {
    border-radius: 0.7rem;
}

.hc-sec-1 {
    padding-right: 5rem;
}

.happyclient-counter {
    width: 6rem;
    border: 1px solid red;
    background-color: white;
    margin-left: 3rem;
}

.happuclientCounter {
    position: absolute;
    background-color: white;
    width: 10.5rem;
    height: 5rem;
    margin-left: -3rem;
    margin-top: 4rem;
    border-radius: 0.5rem;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(164, 174, 198, 0.2);
    border-radius: 0.4rem;
}

.client-para-sec {
    padding-right: 2rem;
}

.cprogresscounter {
    position: absolute;
    background-color: white;
    width: 11.5rem;
    height: 10rem;
    margin-left: -3.8rem;
    margin-top: 13rem;
    border-radius: 0.5rem;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(164, 174, 198, 0.2);
    border-radius: 0.4rem;
}

.hc-icon-para {
    margin: 0.1rem;
}
.happycount-sec2{
    margin-left: 0.6rem;
}
@media (min-width: 992px) {
    .happy-client-main-sec {
        margin-top: 6rem;
    }
}

@media (min-width: 1400px) {
    .hc-para {
        padding-right: 1.5rem;
    }

    .cprogresscounter {
        margin-top: 16rem;
    }

    .happy-client-main-sec {
        margin-top: 6rem;
    }

    .client-para-sec {
        padding-right: 0rem;
    }

    .hc-sec-1 {
        padding-top: 4rem;
    }
}