@keyframes slide-up-company-name {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}
.animated-company {
    transform-origin: top;
    animation: slide-up-company-name .8s;
}
.heading-item{
    font-size: 1.35rem;
    color: #343f52;

}