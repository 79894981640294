.f-script {
    width: 2.2rem;
    height: 2.2rem;
}

.f-head {
    color: #d83a64;
    font-weight: 600;
}

.feature-main-sec {
    margin-top: 4rem;
}

.features-heading-sec {
    font-weight: 600;
    font-size: 1.3rem;

}
/* @media all and (min-width:576px) {

    .f-head-main {
        padding-left: 0rem;
        padding-right: 0rem;
    }
} */
@media all and (min-width:992px) {

    .f-head-main {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

@media (min-width: 1200px) {
    .control-sec-2 {
        padding-left: 6rem;
    }
}

@media (min-width: 1400px) {
    .f-head-main {
        padding-left: 15rem;
        padding-right: 15rem;
    }
}