.as-icon {
    margin-top: 0.2rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #43c1b9;
    border-radius: 50%;
}
.as-fa-check {
    font-size: 0.4rem;
    color: white;
}
.as-icon-1 {
    color: white;
    font-weight: 600;
    margin-top: 0.2rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #43c1b9;
    border-radius: 50%;
}
.About-main {
    margin-top: 7rem;
}

@media (min-width: 1200px) {
    .about-main-sec-2 {
        padding-left: 2rem;
    }
}
@media (min-width: 1400px) {
    .about-main-sec-2 {
        margin-top: 5rem;
    }
}