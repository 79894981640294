.control-icon {
    margin-right: 0.4rem;
    margin-top: 0.2rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #43c1b9;
    border-radius: 50%;
}

.control-fa-check {
    font-size: 0.4rem;
    color: white;
}

.control-sec-main {
    margin-top: 5rem;
}

@media all and (min-width:992px) {
    .control-img {
        /*padding-left: 5rem;*/
    }
}

@media all and (min-width: 1400px) {
    .control-sec-2 {
        padding-top: 4rem;
    }
}


@media all and (min-width: 1200px) {
    .control-sec-2 {
        padding-left: 6rem;
    }
}

@media all and (max-width: 991px) {
    .control-sec-2 {
        padding-top: 2rem;
    }
}