.active{
    color: #43c1b9 !important;
}
a{
    cursor: pointer;
}

.modal-dialog{
    top:50px;
    border:none;
}

.modal-button{
    background-color: rgba(255, 255, 255, 0.95) !important;
    height: 30px !important;
    width: 30px !important;
    top:-1rem;
    right: -1rem;
}
.modal-button> .btn-close{
    height: 0 !important;
    width:0 !important;
}
