.thanks{
    height: 60px;
    padding: 20px;
    width: 60px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.thank-title{
    font-size: 40px;
}
.thanks-details{
    max-width:450px;
}
.thank-sub-title{
    font-size: 30px;
}