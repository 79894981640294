@keyframes slide-up-name {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}

.animated-name {
    transform-origin: top;
    animation: slide-up-name 0.8s;
}

.valid-error {
    color: red;
    font-size: 14px;
    bottom: -6px;
    left: 2%;
}

.valid-error-sm {
    color: red;
    font-size: 14px;
    left: 2%;
}

@media (max-width: 992px) {
    .valid-error {
        left: 7%;
    }

    .valid-error-sm {
        left: 7%;
    }
}
