.underline {
  position: relative;
  z-index: 2;
  white-space: nowrap;
}
.process-main{
    margin-top: 3.5rem;
    margin-bottom: 3rem;
}
.underline:after {
  content: "";
  display: block;
  background: rgba(63, 120, 224, 0.12);
  border-radius: 5rem;
  position: absolute;
  width: 102.5%;
  height: 30%;
  left: -1.5%;
  bottom: 9%;
  z-index: -1;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 992px) {
  .process-wrapper.line [class*="col-"] {
    position: relative;
  }
}
.process-wrapper.line .btn-circle {
  position: relative;
  z-index: 2;
}

@media (min-width: 1200px) {
  .btn.btn-circle.btn-lg,
  .btn.btn-circle {
    font-size: 1.3rem;
  }
}
.btn.btn-circle.btn-lg,
.btn.btn-circle {
  width: 3rem;
  height: 3rem;
  font-size: calc(1.255rem + 0.06vw);
}
.btn.btn-circle:disabled,
.btn.btn-circle.disabled,
fieldset:disabled .btn.btn-circle {
  opacity: 1;
}
.btn.btn-circle {
  color: white;
  border-color: #43c1b9;
  background-color: #43c1b9;
  padding: 0;
  border-radius: 100% !important;
  width: 2.2rem;
  height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}
.btn-lg,
.btn {
  padding: 0.65rem 1.4rem;
  font-size: 0.85rem;
  border-radius: 0.4rem;
}
.btn-soft-primary {
  background-color: #e0e9fa;
  color: #43c1b9;
}
.process-col{
    padding: 0 1.5rem;
}
@media (min-width: 992px) {
  .process-wrapper.line [class*="process-col"]:not(:last-child):after {
    width: 100%;
    position: absolute;
    content: "";
    height: 1px;
    background: none;
    border-top: 1px solid rgba(164, 174, 198, 0.2);
    top: 1.5rem;
    z-index: 1;
    left: 3rem;
  }
}
