.coms-main-bg::after {
    content: "";
    background-image: url("../assets/img/map.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.coms-main-bg {
    position: relative;
}
.com-inner-sec{
    padding-bottom: 8rem;
}

.coms-main-bg>* {
    z-index: 10;
    position: relative;
}

.community-heading-main {
    color: #ef3f6e;
    line-height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
}

.community-btn-main {
    background-image: linear-gradient(120deg, #f857a6 10%, #ef3f6e 100%);
    color: white;
    padding: 0.8rem 1.4rem;
    border-radius: 0.4rem;
}

.community-btn-main:hover {
    color: white;
    transform: translateY(-0.15rem);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.coms-main-bg {
    margin-top: 7rem;
}

@media all and (min-width:768px) {
    .com-sec-head {
        padding: 0rem 6rem 0rem 6rem;
    }
}

@media all and (min-width:992px) {

    .com-sec-head {
        padding: 0 4rem;
    }
}

@media all and (min-width: 1200px) {
    .com-sec-head {
        padding: 0rem 10rem 0rem 10rem;
    }

}

@media all and (min-width:1400px) {
    .com-sec-head {
        padding: 0rem 15rem 0rem 15rem;
    }
}

@media all and (min-width:1479px) {
    .coms-main-bg::after {
        border-radius: 1rem;
    }
}