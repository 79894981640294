.animated-start {
    transform-origin: top;
    animation: slide-up-start 0.8s;
}

.animated-start img {
    width: 33rem;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .animated-start img{
        width: 400px;
    }
}

@media only screen and (max-width: 767px) {
    .animated-start img{
        width: 400px;
    }
}
@media only screen and (max-width: 567px) {
    .animated-start img{
        width: 320px;
    }
}

@keyframes slide-up-start {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}