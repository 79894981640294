.client-z-img{
    width: 7rem;
}
.client-img-sec2{
    margin-top: 2rem;
}
.client-inner-sec{
    margin-top: 4rem;
}
@media all and (min-width:992px){
    .client-z-img{
        width: 6.5rem;
    }
    .client-z-img-height{
        margin-top: 1rem;
    }
}
@media all and (min-width:1400px){
    .client-main-para{
        margin-right: 5rem;
    }
    .client-z-img{
        width: 7rem;
    }
}