@font-face {
    font-family: Unicons;
    src: url(../assets/unicons/Unicons.woff) format('woff2'), url(../assets/unicons/Unicons.woff) format('woff');
  }
.progress-list .progressbar.primary svg path:last-child,
.progress-wrap svg.progress-circle path {
    stroke: #43c1b9;
}

.progress-wrap svg.progress-circle path {
    stroke: #43c1b9;
    stroke-width: 3px;
    box-sizing: border-box;
    transition: all 200ms linear;
}

.progress-wrap svg path {
    fill: none;
}

@media (min-width: 576px) {
    .progress-wrap.active-progress {
        opacity: 1;
    }
}

.progress-wrap.active-progress {
    visibility: visible;
    transform: translateY(0);
}

@media (min-width: 576px) {
    .progress-wrap.active-progress {
        opacity: 1;
    }
}
.progress-wrap:after {
    position: absolute;
    font-family: "Unicons";
    content: "\e951";
    text-align: center;
    line-height: 2.3rem;
    font-size: 1.2rem;
    color: #43c1b9;
    left: 0;
    top: 0;
    height: 2.3rem;
    width: 2.3rem;
    cursor: pointer;
    display: block;
    z-index: 1;
    transition: all 200ms linear;
}
.progress-wrap {
    position: fixed;
    right: 1.5rem;
    bottom: 150px;
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
    display: block;
    border-radius: 100%;
    box-shadow: inset 0 0 0 0.1rem rgb(128 130 134 / 25%);
    z-index: 1010;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0.75rem);
    transition: all 200ms linear, margin-right 0ms;
}

.efgh {
    transition: stroke-dashoffset 10ms linear 0s;
    stroke-dasharray: 318.919, 307.919;
    stroke-dashoffset: 71.102;
}