.animated-role {
    transform-origin: top;
    animation: slide-animated-role 0.8s;
}

@keyframes slide-animated-role {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}