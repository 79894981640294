.navbaris {
    position: fixed;
    z-index: 14;
    height: 4rem;
    width: 100%;
    box-shadow: 0 0 1.25rem rgb(30 34 40 / 6%);
    background: rgba(255, 255, 255, 0.97);
}

.btn-primary {
    transition: background-color 0.3s, color 0.3s;
    transition: all 0.5s ease-in-out;

}

.btn-primary:hover {
    background-color: lightseagreen !important;
    transform: translateY(-2px);
}

.btn-primary:focus{
box-shadow: none !important;
}

.primary {
    background: #43c1b9 !important;
    color: white;
    outline: none !important;
}


.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
}

.nav-container {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 1479px;
}

/* Animation */
.navbar-sec {
    background-color: white;
    opacity: 0.7;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
}

.nav-links {
    text-decoration: none;
    color: #343f52;
    font-weight: 600;
}

.nav-links:hover {
    color: #43c1b9;
    transition: 0.3s;
}

.nav-social:hover {
    transform: translateY(-0.15rem);
    cursor: pointer;
    fill: #43c1b9;
    transition: all 0.3s ease-in-out;
}

.navbar-main {
    height: 4.6rem;
}

.nav-section {
    width: 100%;
}

.nav-social-icons {
    margin-right: 1rem;
}

.is-active {
    color: #43c1b9;
    font-weight: bold;
}

@media all and (max-width: 430px) {
    .nav-social-icons {
        margin-right: 0.7rem;
        width: 0.8rem;

    }

    .nav-logo {
        width: 5rem;
    }
}

@media all and (max-width: 360px) {
    .nav-logo {
        width: 6rem !important;
    }
}

@media all and (max-width: 1200px) {
    .nav-logo {
        width: 6rem !important;
    }

}

@media all and (max-width: 1479px) {
    .nav-logo {
        width: 6rem !important;
    }

    .nav-container {
        padding-left: 1rem;

    }

    @media all and (min-width: 1400px) {
        .nav-logo {
            margin-left: 1rem;
        }
    }


}
