@keyframes slide-up-email {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}
.animated-email {
    transform-origin: top;
    animation: slide-up-email 0.8s;
}