@charset "UTF-8";

body {

  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
}

:root {

  --bs-font-sans-serif: "urbanist", sans-serif;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 20px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-weight: 500;
  --bs-body-font-size: 0.8rem;
  --bs-body-color: #60697b;
  --bs-body-bg: #fefefe;
  --bs-body-line-height: 1.7;
}

:root {
  font-size: var(--bs-root-font-size);
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: #343f52;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.fs-16 {
  font-size: 0.8rem !important;
}

.fs-23 {
  font-size: 1.15rem !important;
}

.fs-22 {
  font-size: 1.1rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.lh-sm {
  line-height: 1.5 !important;
}
@media (min-width: 1200px){
  .fs-28 {
      font-size: 1.4rem !important;
  }
  }
  .fs-28 {
      font-size: calc(1.265rem + 0.18vw) !important;
  }
.lead {
  font-size: 0.9rem;
  font-weight: 500;
}

.gradient-1 {
  background-image: linear-gradient(120deg, #43c1b9 10%, #43c1b9 100%);
}

.fs-15 {
  font-size: 0.75rem !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #aab0bc !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: #43c1b9 !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #aab0bc !important;
}
.text-gradient {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.shadow-lg {
  box-shadow: 0rem 0.25rem 1.75rem rgb(30 34 40 / 7%) !important;
}

@media (min-width: 1400px) {
  .pe-xxl-15 {
    padding-right: 6rem !important;
  }
}

@media (min-width: 1200px) {
  .me-xl-5 {
    margin-right: 1.25rem !important;
  }
}

@media (min-width: 1200px) {
  .counter-wrapper .counter {
    font-size: 2.05rem;
  }
}

.counter-wrapper .counter {
  font-size: calc(1.33rem + .96vw);
}

@media (min-width: 1200px) {
  .counter-wrapper .counter {
    font-size: 2rem;
  }
}

.counter-wrapper .counter {
  font-size: calc(1.325rem + 0.9vw);
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 0.5rem;
}
.text-body {
  --bs-text-opacity: 1;
  color: #60697b !important;
}

.h3,
h3 {
  font-size: 1.15rem;
}

.mb-9 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}


/* Border of Proud section */

.card-border-bottom[class*=border-soft-]:after {
  border-bottom-width: 6px;
  border-top-width: calc(0.4rem - 6px);
}

.card-border-bottom:after {
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-width: 6px;
  border-top-width: calc(0.4rem - 4px);
  border-bottom-style: solid;
  border-top-style: solid;
  border-bottom-color: #43c1b9;
  border-top-color: transparent;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

[class*=card-border-]:after {
  content: "";
  position: absolute;
}

/* bootstrap button outline */
.btn:focus {
  box-shadow: none;
}

/* bootstrap button outline */
/* border end */


/* Email field */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.form-floating {
  position: relative;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.form-floating>.form-control {
  padding: 0.6rem 1rem;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(2.5rem + 2px);
  line-height: 1.25;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.7;
  color: #60697b;
  background-color: #fefefe;
  background-clip: padding-box;
  border: 1px solid rgba(8, 60, 130, 0.07);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgb(30 34 40 / 4%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input,
textarea,
select {
  -webkit-appearance: none !important;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-floating.input-group label {
  margin-left: 0.05rem !important;
}

.form-floating>label {
  padding-top: 0.65rem;
}

.form-floating>label {
  color: #959ca9;
  font-size: 0.75rem;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.6rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  z-index: 10;
}

/* email end */


/* icon*/
[class^=uil-], [class*=" uil-"] {
  font-family: "Unicons" !important;
}
[class^=uil-], [class*=" uil-"], [class^=icn-], [class*=" icn-"] {
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-spacing: normal;
}

/* end */