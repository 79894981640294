.modalContainer {
    padding: 2rem;
    background-image: url("../../assets/img/photos/southPopUp.png");
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
}

.close-button {
    border-radius: 50% !important;
    height: 32px;
    right: -2rem;
    top: -2.6rem;
    background: #43c1b9;
    border-color: #43c1b9;
}

.close-button:hover {
    border-color: #43c1b9;
    z-index: 1000;
}

.form-container {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 90%;
}

.input-form {
    outline: none;
    border: none;
    border-bottom: 1px solid #343f52;
    margin: 35px 0 35px 20px;
    font-size: 1.2rem;
    background: transparent;
}

.button-prev {
    background-color: #43c1b9 !important;
    border-color: #43c1b9 !important;
    padding: 0.5rem 1rem !important;
}

.button-prev:hover {
    border-color: #43c1b9 !important;
}

.button-next {
    background-color: #43c1b9 !important;
    border-color: #43c1b9 !important;
    padding: 0.5rem 1.3rem !important;
}

.button-next:hover {
    border-color: #43c1b9 !important;
}

.form-detail::after {
    position: absolute;
    content: "";
    background-color: #43c1b9;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -6px;

}

.formModal {
    height: 500px
}

.modal-content {
    border-radius: 1rem !important;
}

@media (max-width: 567px) {
    .formModal {
        height: 550px
    }

}