.footer-links {
    text-decoration: none;
    color: black;
}

.footer-links:hover {
    color: rgb(76, 76, 219);
    transition: 0.3s;
}

.footer-social:hover {
    transform: translateY(-0.15rem);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.footer-main-sec{
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.footer-email-link,
.footer-para{
    color: #60697b;
}
.footer-touch {
    padding: 0rem 3rem 0rem 12px;
}
.footer-btn{
    border-radius: 0rem 10px 10px 0rem !important;
    background-image: linear-gradient(120deg, #f857a6 10%, #ef3f6e 100%);
    color: white;
    padding: 1rem 1rem;
    border-radius: 0.4rem;
    border: white;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    background-color: white !important;
    border: 1px solid rgba(109, 118, 129, 0.07) !important;
    height: calc(0.7rem + 2px) !important;
    line-height: 1.25 !important;
}
.footer-btn-main {
    height: calc(2.5rem + 2px) !important;
    background-image: linear-gradient(120deg, #f857a6 10%, #ef3f6e 100%);
    color: white;
    padding: 0.8rem 1.4rem;
    border-radius: 0 0.4rem 0.4rem 0 !important;
    outline: none !important;
    border: none !important;
}

@media all and (min-width:1200px) {
    .footer-touch {
        padding: 0rem 6rem 0rem 12px;
    }
}
@media all and (min-width:1400px) {
    .footer-touch {
        padding: 0rem 7rem 0rem 12px;
    }
}