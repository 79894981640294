.navmobile-main {
    position: fixed;
    width: 80vw;
    height: 100vh;
    z-index: 15;
    color: #43c1b9;
    background-color: #1e2128;
}
.navmobile-links {
    color: white;
}
.navmobile-links:hover {
    color: #43c1b9;
}
.hellooo{
    max-width: none;
}

