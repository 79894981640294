.hs-bg::after {
    content: "";
    background-image: url("../assets/img/photos/bg22.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 0;
}

.hs-bg {
    position: relative;
}

.hs-bg>* {
    z-index: 10;
    position: relative;
}

.hs-row-main {
    padding: 5rem 0 0 0;
}

.hs-img-wrapper {
    margin-left: 1rem;
}

.hs-btn-main {
    background-image: linear-gradient(120deg, #d63384 0%, #43c1b9 100%);
    color: white;
    padding: 0.8rem 1.4rem;
    border-radius: 0.4rem;
}

.hs-btn-main:hover {
    color: white;
    transform: translateY(-0.15rem);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media all and (min-width:720px) {
    .hero-sec1 {
        padding-right: 2rem;
    }
}

@media all and (min-width:992px) {
    .hs-main {
        margin: 0 2rem;
        text-align: start;

    }
}

@media all and (max-width:991px) {
    .hero-sec1 {
        text-align: center;
    }

    .hs-img-wrapper {
        margin-top: 2rem;
    }
}

@media all and (min-width:1400px) {
    .hs-sub-heading {
        padding-right: 6rem;
        color: #60697b;
    }
}
@media all and (min-width:1480px) {
    .hs-bg::after {
        border-radius: 1rem;
    }

    .hs-row-main {
        padding-top: 4rem;

    }

    .hs-vector {
        padding-left: 2rem;
    }

    .hero-sec1 {
        margin-top: 5rem;
    }

    .hs-cont {
        width: 100%;
        padding-right: 0.75rem;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: 0.75rem;
        padding-left: var(--bs-gutter-x, .75rem);
        margin-right: auto;
        margin-left: auto;
        max-width: 1479px;
    }
}

/* animation */

.animated1 {
    animation-delay: 1s;
    animation-duration: 1.3s;
    animation-fill-mode: both;
}

.animated2 {
    animation-delay: 1.5s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animated3 {
    animation-delay: 2s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes imgfadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-3px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.imgfadeInDown {
    animation-name: imgfadeInDown;
}

.imganimated {
    animation-delay: 0.2s;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-45px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
}

/* animation end */