.contact1-main{
    margin-top: 10rem;
}
.contact-textarea{
    min-height: 10rem !important;
}
.uil-location-pin-alt:before {
    font-family: "Unicons";
    content: "\ebd8";
}
.uil-phone-volume:before {
    font-family: "Unicons";
    content: "\ec50";
}
.uil-envelope:before {
    content: "\eac8";
}
.contact-detail-sec{
    margin-top: 4rem;
}
.contact-send-btn{
    padding: 0.575rem 1.2rem !important;
}
@media all and (min-width:992px){
    .contact-sec1{
        padding-left: 5rem;
        padding-right: 3rem;
    }
    .contact-detail-sec{
        margin-top: 0rem;
    }
}
@media all and (min-width:1200px){
    .contact-address{
        padding-right: 4rem;
    }
}