.topsideimg{
    border-radius: 10px;
    overflow: hidden;
    height: 30rem;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-bg{
    background-image: url('../../assets/img/main-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5.5rem 0;
    height: 100vh;
    background-attachment: fixed;
}
.hero-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Additional styles for the div */
    height: 200px;
    width: 60%;

}
.text-strok{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 4px;
    /*background-image: linear-gradient(90deg,#ff00d2,#ff00d2,#fed90f,#09f1b8);*/
    background-image: linear-gradient(289.6deg,#000000 191%, #E50D12 -13.32%, #7F1138 18.88%, #280F2F 52.5%, #0D112C 89.75%, #000000 191%, #000000 191%);
    background-clip: text;
    font-weight: bold;
    font-size:3rem ;
}
.min-strok{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    /*background-image: linear-gradient(90deg,#ff00d2,#ff00d2,#fed90f,#09f1b8);*/
    background-clip: text;
    font-weight: bold;
    font-size:1.5rem ;
}

.img-fluid{
    display: block;
    width: 100%;
}
.img-absol{
    width: 6rem;
}
.hero-date{
    color: white;
    font-size: 1rem;
    background-color:#43c1b9 ;
    padding: 1rem 1.5rem;
    display: block;
    width: fit-content;
    /*border-bottom-left-radius: 56px;*/
    border-radius: 10px;
    margin:3rem auto auto;


}
/* Define the animation */
@keyframes wavy {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

/* Apply the animation to the text */
.animated-text {
    animation: wavy 1.5s infinite; /* Adjust the duration as needed */
    display: inline-block;
    border-bottom: 2px solid #43c1b9 ;
    color: white;
    margin-top: 8rem;
}
.giant-explain{
    margin-right: -3.5rem;
    box-shadow: 0 2px 15px -3px rgba(0,0,0,0.07), 0 10px 20px -2px rgba(0,0,0,0.04);
    z-index: 1;
    backdrop-filter: blur(30px);
    border-radius: 0.5rem;
    background-color: white;
    text-align: start;
}
.explain-img{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    overflow: hidden;
}
.gitex-head-outer{
    margin:5rem 0
}
.gitex-head{
    font-size: 1.8rem;
    text-align: center;
}

.subhead-offers{
    font-size: 1rem;

}
.offers-contect-outer{
    padding: 3rem;
    border-radius: 0.5rem;
    border: 1px solid #43c1b9 ;
    box-shadow:  #43c1b9  0 5px 15px;
}
.offers-img{
border-radius: 0.5rem;
    overflow: hidden;
}
.offer-crd-head{
    font-size: 1.5rem;
}
.offer-crd-p{
    font-size: 1rem;
}


#about-section {
    position: relative;
    margin: 4rem 0;
}

.blockabout {
    padding: 20px;
    border-radius: 0.5rem;
    margin-left: -3.5rem;
    box-shadow: 0 2px 15px -3px rgba(0,0,0,0.07), 0 10px 20px -2px rgba(0,0,0,0.04);
    z-index: 1;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: white;
    text-align: start;
}

.blockabout-inner {
    padding: 30px;
    border: 1px solid rgba(32, 33, 36, 0.1);
}

.sosmed-horizontal a i {
    border: 1px solid #070707;
    border-radius: 50%;
    color: #070707;
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin: auto 3px;
    font-size: 15px;
    text-align: center;
    transition: all 0.3s;
}

.rey-btn {
    padding: 10px 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    font-weight: 700;
    transition: all 0.3s;
    background-color:#43c1b9;
    color: white;
}
.title-south{
    font-size: 1.5rem;
}
.description-p{
    font-size: 1rem;
}
.dev-slam{
    font-size: 1rem;
    font-weight: 800;
    color: black;
}
.potoaboutwrap{
border-radius: 0.5rem;
    overflow: hidden;
}


#intro-south{
    margin-top: 2rem;
}

#header>h1{
    text-align:center;
    font-size:3rem;
}
#header>p{
    text-align:center;
    font-size:1.5rem;
    font-style:italic;
}
.giTex-container{
    width:100vw;
    display:flex;
    justify-content:space-around;
    flex-wrap:wrap;
    padding:40px 20px;
}
.south-card{
    display:flex;
    flex-direction:column;
    width:400px;
    margin-bottom:60px;
}
.card>div{
    box-shadow:0 15px 20px 0 rgba(0,0,0,0.5);
}
.south-card-image{
    width:400px;
    height:250px;
    border-radius: 0.5rem;
    overflow: hidden;

}
.south-card-image>img{
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:bottom;
}
.card-text{
    margin:-30px auto;
    margin-bottom:-50px;
    height:432px;
    width:300px;
    background: linear-gradient(-45deg, #41c6a0 0%, #59bdc6 100% );
    color:black;
    padding:20px;
    border-radius: 0.5rem;
}
.card-meal-type{
    font-style:italic;
}
.card-title{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 131.25% */
    letter-spacing: 0.32px;
    height: 70px;
}
.south-card-body{
    font-size:1rem;
    padding: 10px;
    border-radius: 20px;
    background-color:#00000040;
    color: white;
    height:295px;
}
.more-detail-left{
    width: 30%;
    height: 100%;
}
.more-detail-outer{
    display: flex;
    align-items:center;
    justify-content: center;
    border:1px solid grey;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
}
.south-explain{
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: black;
    box-shadow: grey 0 3px 15px;
}
.south-explain-inner{
    padding:1rem;
    height: 100%;
}
.south-explain-h{
    font-size: 1.5rem;
}
.south-explain-p{
    font-size: 0.8rem;
    color: black;
}
.visiting-icons{
    height: 1.7rem;
    width: 1.7rem;
    margin-bottom: 0.5rem;
}

.participating-card{
    position: relative;
    height: 254px;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
    z-index: 2;
}

.participating-card::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    height: 245px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #41c6a0 0%, #59bdc6 100% );
    z-index: -1;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.participating-card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
}

.participating-heading {
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 500;
    color: white;
    margin-top: 16px;
}

.participating-card p:not(.participating-heading) {
    font-size: 20px;
}

.participating-card p:last-child {
    color: #ffffff;
    font-weight: 600;

}

.card-outer{
    position: relative;
    margin: 4px;
    height: 67%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;
    padding: 4px 41px;
    border: 1px solid white;
    background-color:#00000040;
}



.card-outer::before {
    top: 0;
    right: 0;
    border-radius: 0 15px 0 100%;
}

.card-outer::after {
    bottom: 0;
    left: 0;
    border-radius: 0 100%  0 15px;
}


@media (max-width:1400px) {
    .south-explain-inner{
        padding:2rem;
    }

}

@media (max-width:991px) {
    .giant-explain{
        margin-right:0;
    }
    .offers-contect-outer{
        box-shadow:  #43c1b9  0 3px 5px;
    }
    .blockabout{
        margin-left: 0;
        margin-top: -4rem;
    }
}

@media (max-width:768px) {
    .hero-content {
       width: 75%;
    }

}


@media (min-width:576px) {
    .giTex-container{
        max-width: 540px;
    }

}
@media (min-width:768px) {
    .giTex-container{
        max-width: 720px;
    }

}
@media (min-width:992px) {
    .giTex-container{
        max-width: 960px;
    }

}
@media (min-width:1200px) {
    .giTex-container{
        max-width: 1140px;
    }

}
@media (min-width:1400px) {
    .giTex-container{
        max-width: 1320px;
    }

}


