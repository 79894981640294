.ps-bg::after {
    content: "";
    background-image: url("../assets/img/photos/bg22.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    width: 100%;
    height: 65%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 0;
}

.ps-bg {
    position: relative;
}

.prod-main-sec {
    margin-top: 7.5rem;
}

.ps-bg>* {
    z-index: 10;
    position: relative;
}

.p-card-main :before {
    content: "\201d";
    position: absolute;
    top: 0.5rem;
    left: 1.1rem;
    color: hsl(180deg 51% 52% / 23%);
    font-size: 10rem;
    line-height: 1;
    z-index: 1;
    opacity: 0.3;
}

.card-border-bottom {
    position: relative;
}

.p-card-main {
    position: relative;
    border: 1px solid white;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
}

.ps-col-1 {
    text-align: center;
}
.ps-shadow-lg {
    box-shadow: 0rem 0.25rem 1.75rem rgb(30 34 40 / 7%) !important;
}
.p-card-content{
    max-height: 260px;
    overflow-y: scroll;
}
.p-card-content::-webkit-scrollbar{
    display: none;
}
.ps-cont {
    width: 100%;
    padding-right: 0.75rem;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: 0.75rem;
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 1479px;
}
.scollar{
    height: 465px;
    /*overflow-y: auto;*/
}
.scollar::-webkit-scrollbar{
    display: none;
}

@media all and (min-width:320px) {
    .ps-bg::after {
        height: 45%;
    }
}

@media all and (max-width:992px) {
    .ps-bg::after {
        height: 50%;
    }

    .ps-head-para {
        margin-right: -3rem;
    }
}

.ps-head-para {
    margin-right: 0rem;
}

@media all and (min-width:1200px) and (max-width:1400px) {
    .ps-head-para {
        margin-right: -3rem;
    }
}

@media all and (min-width:992px) {
    .ps-col-1 {
        text-align: start;
    }
}

@media all and (min-width:1200px) {
    .ps-bg::after {
        height: 67%;
    }
}

@media all and (min-width:1479px) {
    .ps-bg::after {
        border-radius: 1rem;
    }

}