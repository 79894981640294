.term-header {
    height: 20rem;
    background-color: #f1f5fd;
}

p{
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
}
.term-link a{
    font-size: 0.8rem;
    font-weight: 500;
   line-height: 12px;
    color: #60697b;
}
.term-link a:hover{
    color: #43c1b9;
}
.term-header div li a:hover{
    color: #43c1b9;
}
.term-aside{
    position: sticky;
    top: 100px;
    height: 100vh;
}
.term-header p {
    text-align: center;
    height: max-content;
    color: #343f52;
}
.term-header div li a {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 12px;
    color: #60697b;
}

.term-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(164, 174, 198, 0.2);
    border-radius: 0.4rem;
    box-shadow: 0 0 0 0.05rem rgb(8 60 130 / 6%), 0 0 1.25rem rgb(30 34 40 / 4%);
}

.term-icon {
    width: 20px;
    height: 20px;
    background-color: #43c1b9;
}

@media all and (max-width: 400px) {
    .term-icon {
        width: 27px;
        height: 20px;
        background-color: #43c1b9;
    }
}

.term-section {
    background: white;
}

@media all and (max-width: 570px) {
    .term-sm-container {
        margin: 0 0.5rem;
    }
}
